import { LinearProgress, Typography, makeStyles, Avatar, Grid } from "@material-ui/core";
import * as React from "react";
import { alpha } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => ({
  profile: {
    width: "100%",
    marginTop: theme.spacing(0.5)
  },
  container: {
    width: "175px",
    marginBottom: theme.spacing(1),
    backgroundColor: alpha(theme.palette.grey[300], 0.2)
  },
  great: {
    backgroundColor: theme.palette.success.main
  },
  warn: {
    backgroundColor: theme.palette.warning.main
  },
  error: {
    backgroundColor: theme.palette.error.main
  },
  bgBar: {
    backgroundColor: theme.palette.common.white
  },
  scoreBar: {
    marginLeft: theme.spacing(3)
  },
  scoreText: {
    fontSize: "1rem",
    fontWeight: 600,
    color: theme.palette.common.black
  },
  title: {
    marginTop: theme.spacing(0.5)
  },
  titleText: {
    color: theme.palette.common.white,
    fontSize: "0.875rem",
    fontWeight: 600
  }
}));

const ProfileScoreComponent: React.FunctionComponent<{ score: number }> = (props) => {
  const classes = useStyles();
  const getColor = (score: number) => {
    if (score >= 90) {
      return classes.great;
    } else if (score >= 25) {
      return classes.warn;
    }
    return classes.error;
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={2}>
        <Avatar variant="square" className={getColor(props.score)}>
          <Typography className={classes.scoreText}>{props.score}</Typography>
        </Avatar>
      </Grid>
      <Grid item xs="auto" className={classes.scoreBar}>
        <div className={classes.title}>
          <Typography className={classes.titleText}>Profile Health</Typography>
        </div>
        <LinearProgress
          variant="determinate"
          value={props.score}
          classes={{ barColorPrimary: getColor(props.score), colorPrimary: classes.bgBar }}
        />
      </Grid>
    </Grid>
  );
};

export default ProfileScoreComponent;
