import { Typography, makeStyles, Grid } from "@material-ui/core";
import * as React from "react";
import Icon from "@mui/material/Icon";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "150px",
    height: "35px",
    alignItems: "center",
    backgroundColor: theme.palette.error.main
  },
  error: {
    backgroundColor: theme.palette.error.main
  },
  image: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0.2),
    alignContent: "center"
  },
  title: {
    justifyContent: "center"
  },
  titleText: {
    color: theme.palette.common.white,
    fontSize: "1.25rem",
    fontWeight: 1200
  }
}));

const InactiveScoreComponent: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={3} className={classes.image}>
        <Icon>
          <ErrorOutlineIcon />
        </Icon>
      </Grid>
      <Grid item xs="auto">
        <div className={classes.title}>
          <Typography className={classes.titleText}>Inactive</Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default InactiveScoreComponent;
