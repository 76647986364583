import { useState, useEffect, memo } from "react";
import {
  Button,
  makeStyles,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  IconButton,
  Fade,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useFormik } from "formik";
import { PersonEducation, PersonEducationType } from "../../models/people";
import { PersonEducationUpsertInput } from "../../api/GraphQL/mutations";
import dateFormatter from "./formatting/dateFormatter";

const useStyles = makeStyles((theme) => ({
  actions: {
    display: "flex",
    "& > *:first-child": {
      flexGrow: 1
    }
  },
  editPaper: {
    padding: theme.spacing(2)
  },
  editButton: {
    opacity: 0
  },
  deleteButton: {
    color: theme.palette.error.main,
    "&.MuiButton-root:hover": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.black
    }
  }
}));

const EditablePersonEducation = (props: {
  education: PersonEducation | PersonEducationUpsertInput;
  editOnly?: boolean;
  canEdit?: boolean;
  onSave: (value: PersonEducation | PersonEducationUpsertInput) => Promise<void>;
  onDelete?: () => Promise<void>;
  onCancel?: () => void;
}) => {
  const classes = useStyles();
  const { education, onSave, editOnly, canEdit, onCancel, onDelete } = props;
  const [isEditing, setIsEditing] = useState(editOnly);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const formik = useFormik({
    initialValues: {
      ...education,
      dateAchieved: education.dateAchieved
    } as PersonEducation | PersonEducationUpsertInput,
    onSubmit: async (value) => {
      setIsSaving(true);
      try {
        await onSave(value);
        setIsEditing(false);
      } catch (err) {
        console.log(err);
      }
      setIsSaving(false);
    },
    validate: (value) => {
      const errors = {} as { [key in keyof PersonEducationUpsertInput]: string };
      const requiredFields: (keyof PersonEducationUpsertInput)[] = ["type", "institution", "name", "dateAchieved"];
      for (const field of requiredFields) {
        if (!value[field]) {
          errors[field] = "Required";
        }
      }
      if (value.type === PersonEducationType.Unknown) {
        errors.type = "Required";
      }
      if (value.institution && value.institution.length > 128) {
        errors.institution = "Too long";
      }
      if (value.name && value.name.length > 128) {
        errors.name = "Too long";
      }
      if (value.description && value.description.length > 512) {
        errors.description = "Too long";
      }
      if (value.linkUri && value.linkUri.length > 1024) {
        errors.linkUri = "Too long";
      }
      return errors;
    }
  });

  const handleDeleteClick = async () => {
    setIsDeleting(true);
    try {
      await onDelete!();
    } catch (err) {
      console.log(err);
    }
    setIsDeleting(false);
  };

  useEffect(() => {
    formik.setValues({
      ...education,
      dateAchieved: education.dateAchieved
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [education]);

  const cancelEdit = () => {
    setIsEditing(false);
    formik.setValues({
      ...education,
      dateAchieved: education.dateAchieved
    });
    !!onCancel && onCancel();
  };

  return canEdit && isEditing ? (
    <Fade in>
      <Paper className={classes.editPaper}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel>Type</InputLabel>
                <Select name="type" label="Type" value={formik.values.type} onChange={formik.handleChange}>
                  <MenuItem value={PersonEducationType.Unknown}>Select a type</MenuItem>
                  <MenuItem value={PersonEducationType.Degree}>Degree</MenuItem>
                  <MenuItem value={PersonEducationType.Diploma}>Diploma</MenuItem>
                  <MenuItem value={PersonEducationType.Course}>Course</MenuItem>
                  <MenuItem value={PersonEducationType.Certification}>Certification</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Name"
                value={formik.values.name}
                error={!!formik.errors.name}
                onChange={formik.handleChange}
                fullWidth
                helperText={formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="institution"
                label="Institution"
                value={formik.values.institution}
                error={!!formik.errors.institution}
                onChange={formik.handleChange}
                fullWidth
                helperText={formik.errors.institution}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="date"
                name="dateAchieved"
                label="Date achieved"
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={formik.values.dateAchieved}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.actions}>
                <div>
                  <Button type="submit" disabled={!formik.isValid || isSaving || isDeleting}>
                    {isSaving ? "Saving..." : "Save"}
                  </Button>
                  <Button onClick={cancelEdit}>Cancel</Button>
                </div>
                {onDelete && (
                  <div>
                    <Button className={classes.deleteButton} onClick={handleDeleteClick} disabled={isSaving || isDeleting}>
                      {isDeleting ? "Deleting..." : "Delete"}
                    </Button>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Fade>
  ) : (
    <ListItem>
      <ListItemText
        primary={education.name}
        secondary={`${education.institution}, ${dateFormatter.formatCalendarDate(education.dateAchieved, "yyyy")} `}
      />
      {canEdit && (
        <ListItemSecondaryAction>
          <IconButton onClick={() => setIsEditing(true)} size="small" color="primary">
            <EditIcon fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default memo(EditablePersonEducation);
