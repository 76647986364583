import { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Card, CardActionArea, CardContent, CardMedia, IconButton, Divider, makeStyles, CardActions } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import MessageIcon from "@material-ui/icons/Message";
import { PeopleSearchResult } from "../../models/people";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  actionArea: {
    flex: 1,
    textDecoration: "none",
    color: "inherit"
  },
  title: {
    alignItems: "stretch"
  },
  contactInfo: {
    alignItems: "flex-end"
  },
  highlight: {
    "& em": {
      color: theme.palette.accent.gold,
      fontStyle: "none"
    }
  }
}));

export interface PeopleSearchResultCardProps {
  searchResult: PeopleSearchResult;
}

const PeopleSearchResultCard = (props: PeopleSearchResultCardProps) => {
  const classes = useStyles();
  const { searchResult } = props;
  const person = searchResult.person;

  return (
    <Card className={classes.root}>
      <RouterLink className={classes.actionArea} to={`/people/${person.id}`}>
        <CardActionArea>
          <CardMedia component="img" image={`${person.photos.length ? person.photos[0].uri : "/images/no_photo.png"}`} />
          <CardContent>
            <div>
              <Typography variant="h5" component="h2">
                {(searchResult.highlights || []).find((h) => h.matchingFieldName === "fullName") ? (
                  <span
                    className={classes.highlight}
                    dangerouslySetInnerHTML={{
                      __html: searchResult.highlights.find((h) => h.matchingFieldName === "fullName")?.matchingTextFragments[0] || ""
                    }}
                  ></span>
                ) : (
                  person.fullName
                )}
              </Typography>
            </div>
            <div className={classes.title}>
              <Typography variant="subtitle1" color="textSecondary">
                {(searchResult.highlights || []).find((h) => h.matchingFieldName === "jobTitle") ? (
                  <span
                    className={classes.highlight}
                    dangerouslySetInnerHTML={{
                      __html: searchResult.highlights.find((h) => h.matchingFieldName === "jobTitle")?.matchingTextFragments[0] || ""
                    }}
                  ></span>
                ) : (
                  person.jobTitle
                )}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {person.department}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {person.officeLocation} {person?.city && <span>&#8226; {person.city}</span>}
              </Typography>
            </div>
          </CardContent>
        </CardActionArea>
      </RouterLink>
      <Divider />
      <CardActions>
        <div className={classes.contactInfo}>
          <IconButton target="_blank" href={`https://teams.microsoft.com/l/chat/0/0?users=${person.emailAddress}&topicname=Chat`}>
            <MessageIcon />
          </IconButton>
          <IconButton href={`mailto:${person.emailAddress}`}>
            <EmailIcon />
          </IconButton>
          {person.phoneNumber && (
            <IconButton href={`tel:${person.phoneNumber}`}>
              <PhoneIcon />
            </IconButton>
          )}
        </div>
      </CardActions>
    </Card>
  );
};

export default memo(PeopleSearchResultCard);
