import * as React from "react";
import { Typography, IconButton, makeStyles, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useMutation, useQuery } from "@apollo/client";
import Button from "@material-ui/core/Button";
import DescriptionIcon from "@material-ui/icons/Description";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
import MessageIcon from "@material-ui/icons/Message";
import PersonWorkingHoursView from "./PersonWorkingHoursView";
import { Person, PersonResume } from "../../models/people";
import { GENERATE_RESUME } from "../../api/GraphQL/mutations";
import { useNotifications } from "./NotificationProvider";
import ProfileScoreComponent from "./ProfileScoreComponent";
import { PersonProfileCompletenessReport } from "../../models/dataQuality";
import { GET_PROFILE_DATA_QUALITY_REPORT } from "../../api/GraphQL/queries";
import InactiveScoreComponent from "./InactiveScoreComponent";

const picSize = 254;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: "linear-gradient(90deg, #000,  #00675B)",
    display: "flex",
    minHeight: 250,
    [theme.breakpoints.down("xs")]: {
      maxHeight: "100% !important",
      flexDirection: "column"
    }
  },
  personInfo: {
    display: "flex",
    alignContent: "flex-start",
    maxHeight: "auto",
    width: "100%",
    color: "#fff",
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      maxWidth: "100%"
    }
  },
  personPhotoWrapper: {
    width: picSize,
    minHeight: picSize,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      height: "auto",
      maxHeight: "100% !important",
      maxWidth: "100% !important"
    }
  },
  personPhoto: {
    height: "100%",
    minHeight: picSize,
    width: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    maxWidth: picSize,
    minWidth: picSize,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      backgroundPosition: "top",
      backgroundSize: "length",
      maxWidth: "none",
      minHeight: 350
    }
  },
  personPhotoInactive: {
    height: "100%",
    minHeight: picSize,
    width: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    maxWidth: picSize,
    minWidth: picSize,
    filter: "grayscale(100%)",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      backgroundPosition: "top",
      backgroundSize: "length",
      maxWidth: "none",
      minHeight: 350
    }
  },
  personContact: {
    marginRight: theme.spacing(1.5),
    height: "auto",
    color: "#fff"
  },
  resumeAndProfileComplete: {
    display: "flex",
    height: "50px",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(7.5)
    }
  },
  generateResumeBtn: {
    color: theme.palette.offCommon.black,
    fontSize: "0.75rem",
    width: "175px",
    height: "38.82px",
    fontWeight: 600,
    backgroundColor: theme.palette.offCommon.white
  },
  infoText: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium as number,
    fontSize: "1rem"
  },
  nameText: {
    color: theme.palette.common.white,
    fontSize: "2.125rem",
    fontWeight: 600,
    marginLeft: -1
  },
  stack: {
    display: "flex",
    flexDirection: "column"
  },
  personDetails: {
    flexGrow: 1,
    padding: `${theme.spacing(2)}px ${theme.spacing(2.5)}px`,
    width: `calc(100% - ${picSize}px)`,
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  personDetailsContactInfo: {
    display: "flex",
    "& > *:first-child": {
      flexGrow: 1
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  rightDetails: {
    alignItems: "flex-end"
  }
}));

export interface PersonInfoProps {
  personId: string;
  person: Person | undefined;
  canEdit: boolean;
}

const PersonInfo: React.FunctionComponent<PersonInfoProps> = (props) => {
  const classes = useStyles();
  const { person, canEdit, personId } = props;
  const { setNotification } = useNotifications();
  const [generatingResume, setGeneratingEvent] = React.useState(false);

  const handleGenerateResume = async () => {
    setGeneratingEvent(true);
    await generateResume({ variables: { id: person!.id } });
  };

  const { data, loading } = useQuery<{ dataQuality: PersonProfileCompletenessReport }>(GET_PROFILE_DATA_QUALITY_REPORT, {
    variables: { id: personId }
  });

  const profileCompletenessScore = data?.dataQuality?.person.overallScore || 0;

  const [generateResume, { error: generateResumeError }] = useMutation<{ personResume: PersonResume }, { id: string }>(GENERATE_RESUME, {
    onCompleted: (data) => {
      setGeneratingEvent(false);

      const downloadUrl = data.personResume.uri;

      const anchor = document.createElement("a");
      anchor.setAttribute("href", downloadUrl);
      anchor.click();
    }
  });

  React.useEffect(() => {
    if (generateResumeError) {
      setNotification(generateResumeError?.message, "error");
    }
  }, [generateResumeError, setNotification]);

  return (
    <div className={classes.root}>
      <div className={classes.personInfo}>
        <div className={classes.personPhotoWrapper}>
          {!!person && (
            <div
              className={person.isEnabled ? classes.personPhoto : classes.personPhotoInactive}
              style={{ backgroundImage: `url('${person?.photos.length ? person.photos[0].uri : "/images/no_photo.png"}'` }}
            ></div>
          )}
          {!person && <Skeleton variant="rect" width="100%" height="100%" />}
        </div>
        <Grid container className={classes.personDetails}>
          <Grid item xs={10}>
            <div className={classes.stack}>
              <Typography component="h1" className={classes.nameText}>
                {person?.fullName}
                {!person && <Skeleton variant="text" width="50%" />}
              </Typography>
              <Typography component="h2" className={classes.infoText}>
                {person?.jobTitle}
                {!person && <Skeleton variant="text" width="45%" />}
              </Typography>
              <Typography component="h2" className={classes.infoText}>
                {person?.department}
                {!person && <Skeleton variant="text" width="45%" />}
              </Typography>
              <Typography component="h2" gutterBottom className={classes.infoText}>
                {person?.officeLocation} {person?.city && <span>&#8226; {person.city}</span>}
                {!person && <Skeleton variant="text" width="35%" />}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={`${classes.stack} ${classes.rightDetails}`}>
              {loading ? (
                <Skeleton variant="rect" width={"100%"} />
              ) : person?.isEnabled ? (
                <ProfileScoreComponent score={profileCompletenessScore} />
              ) : (
                <InactiveScoreComponent />
              )}
              {canEdit && (
                <>
                  {generatingResume ? (
                    <Button variant="contained" className={classes.generateResumeBtn} startIcon={<DescriptionIcon />}>
                      Generating..
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className={classes.generateResumeBtn}
                      startIcon={<DescriptionIcon />}
                      onClick={() => handleGenerateResume()}
                      style={{ textTransform: "none" }}
                    >
                      Generate Resume
                    </Button>
                  )}
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            {!!person && (
              <div className={classes.personDetailsContactInfo}>
                <div>{person?.workingHours ? <PersonWorkingHoursView workingHours={person.workingHours} /> : null}</div>
                <div>
                  <IconButton
                    className={classes.personContact}
                    target="_blank"
                    href={`https://teams.microsoft.com/l/chat/0/0?users=${person.emailAddress}&topicname=Chat`}
                    color="primary"
                  >
                    <MessageIcon viewBox="0 0 24 20" />
                  </IconButton>
                  <IconButton className={classes.personContact} href={`mailto:${person.emailAddress}`} color="primary">
                    <MailOutlineIcon />
                  </IconButton>
                  {person.phoneNumber && (
                    <IconButton className={classes.personContact} href={`tel:${person.phoneNumber}`} color="primary">
                      <CallOutlinedIcon />
                    </IconButton>
                  )}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default React.memo(PersonInfo);
