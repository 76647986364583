import { gql } from "@apollo/client";

export enum ProjectParticipantExclusionFilter {
  IncludedOnly = "INCLUDED_ONLY",
  ExcludedOnly = "EXCLUDED_ONLY",
  All = "ALL"
}

export const GET_PERSON = gql`
  query GetPerson(
    $id: String!
    $participationsFirst: Int
    $participationsAfter: String
    $exclusionFilter: ProjectParticipantExclusionFilter
  ) {
    person(id: $id) {
      id
      givenName
      surname
      jobTitle
      bio
      devFactoWorkDescription
      fullName
      emailAddress
      phoneNumber
      officeLocation
      city
      department
      isContractor
      isEmployee
      isEnabled
      lastUpdatedDateTime
      workingHours {
        startTime
        endTime
        daysOfWeek
        timeZone
      }
      manager {
        id
        givenName
        surname
        fullName
        photos {
          uri
          size
        }
      }
      directReports {
        id
        givenName
        surname
        fullName
        isEnabled
        photos {
          uri
          size
        }
      }
      photos {
        uri
        size
      }
      skills {
        id
        beaconItem {
          id
          name
          category
          previewDescription
        }
        isLoved
      }
      education {
        id
        personId
        type
        institution
        name
        description
        linkUri
        dateAchieved
      }
      priorExperience {
        id
        personId
        companyName
        roleName
        roleDescription
        startDate
        endDate
        skillsUsed {
          beaconItem {
            id
            name
            category
            previewDescription
          }
        }
      }
      projectParticipations(first: $participationsFirst, after: $participationsAfter, exclusionFilter: $exclusionFilter) {
        edges {
          node {
            id
            isExcludedFromProfile
            contributedTimeInMinutes
            firstTimeEntryDate
            lastTimeEntryDate
            role
            duties
            skillsUsed {
              beaconItem {
                id
                name
                category
                previewDescription
              }
            }
            project {
              id
              name
              clients {
                id
                name
                logoUri
              }
              startDate
              dueDate
              isArchived
              status
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  }
`;

export const GET_MANAGERS = gql`
  query GetManagers {
    managers {
      id
      fullName
      photos {
        uri
      }
    }
  }
`;

export const GET_MORE_PARTICIPATIONS = gql`
  query GetPerson(
    $id: String!
    $participationsFirst: Int
    $participationsAfter: String
    $exclusionFilter: ProjectParticipantExclusionFilter
  ) {
    person(id: $id) {
      id
      projectParticipations(first: $participationsFirst, after: $participationsAfter, exclusionFilter: $exclusionFilter) {
        edges {
          node {
            id
            isExcludedFromProfile
            contributedTimeInMinutes
            firstTimeEntryDate
            lastTimeEntryDate
            role
            duties
            skillsUsed {
              beaconItem {
                id
                name
                category
                previewDescription
              }
            }
            project {
              id
              name
              clients {
                id
                name
                logoUri
              }
              startDate
              dueDate
              isArchived
              status
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  }
`;

export const GET_BEACON_ITEMS = gql`
  query GetBeaconItems($first: Int) {
    beaconItems(first: $first) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_PEOPLE_PROFILES_DATA_QUALITY_REPORT = gql`
  query GetPeopleProfilesDataQualityReport {
    dataQuality {
      peopleProfiles {
        person {
          id
          givenName
          surname
          fullName
          managerId
          department
          photos {
            uri
            size
          }
        }
        overallScore
        fields {
          fieldName
          entityId
          score
          weight
          violations {
            description
          }
          children {
            fieldName
            entityId
            score
            weight
            violations {
              description
            }
          }
        }
      }
    }
  }
`;

export const GET_PROFILE_DATA_QUALITY_REPORT = gql`
  query GetProfileDataQualityReport($id: String!) {
    dataQuality {
      person(id: $id) {
        overallScore
        fields {
          fieldName
          entityId
          score
          weight
          violations {
            description
          }
          children {
            fieldName
            entityId
            score
            weight
            violations {
              description
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_PEOPLE = gql`
  query GetAllPeople {
    people {
      id
      fullName
      photos {
        uri
      }
    }
  }
`;

export const SEARCH_PEOPLE = gql`
  query SearchPeople(
    $query: String
    $includeEmployees: Boolean
    $includeContractors: Boolean
    $studios: [String]
    $departments: [String]
    $managers: [String]
    $cities: [String]
  ) {
    peopleSearch(
      query: $query
      includeEmployees: $includeEmployees
      includeContractors: $includeContractors
      studios: $studios
      departments: $departments
      managers: $managers
      cities: $cities
    ) {
      totalResultsCount
      facets {
        fieldName
        values {
          value
          count
        }
      }
      results {
        id
        person {
          id
          givenName
          surname
          jobTitle
          fullName
          emailAddress
          phoneNumber
          officeLocation
          city
          isContractor
          isEmployee
          managerId
          photos {
            uri
            size
          }
          department
        }
        highlights {
          matchingFieldName
          matchingTextFragments
        }
      }
    }
  }
`;

export const GET_LOCATIONS_AND_DEPARTMENTS = gql`
  query SearchPeople($query: String, $includeEmployees: Boolean, $includeContractors: Boolean, $studios: [String], $departments: [String]) {
    peopleSearch(
      query: $query
      includeEmployees: $includeEmployees
      includeContractors: $includeContractors
      studios: $studios
      departments: $departments
    ) {
      totalResultsCount
      facets {
        fieldName
        values {
          value
          count
        }
      }
    }
  }
`;
